/* src/styles/common.scss */

.section {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 24px;
}

.section-title {
  font-size: 28px; /* Adjusted for proper sizing */
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

.section-description {
  font-size: 24px;
  color: #666;
  margin-top: 0.75em;
  margin-bottom: 2em;
}

.section-subtitle {
  font-weight: bold;
}


.input-title {
    font-size: larger;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
}

.input-description {
    font-size: 22px;
    color: #666;
    margin-top: 5px;
}

.input-content {
  font-size: 22px;
  line-height: 1.5;
  margin-left: 2em;
}

/* Flex table layout */
.input-group {
  display: flex;
  align-items: flex-start; /* Align items at the start */
  margin-bottom: 15px;
}

.input-group .button-column {
  margin-right: 20px; /* Space between radio button and content */
}

.input-group .content-column {
  display: flex;
  flex-direction: column;
}

.input-group input[type="radio"] {
  margin-top: 5px; /* Fine-tune alignment */
}




/* Styling for the radio buttons and checkboxes */
[type="checkbox"], [type="radio"] {
  height:24px;
  width:24px;
}

/* Styling for text input boxes */
input[type="text"], textarea {
  padding: 10px;
  font-size: 24pt; /* Set global font size for all text inputs */
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%; /* Ensure full width for input elements */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

input[type="text"]:focus, textarea:focus {
  border-color: #007bff; /* Blue border when focused */
}

.code {
  font-family: 'Courier New', Courier, monospace; /* Monospaced font for code */
  white-space: pre-wrap; /* Preserve spaces and line breaks */
  background-color: #f5f5f5; /* Light gray background */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Optional: Rounded corners */
  border: 1px solid #ddd; /* Optional: Border */
  font-size: 14px; /* Set a readable font size */
  line-height: 1.5; /* Better line spacing */
  overflow-x: auto; /* Allow horizontal scrolling if the code is too long */
}
