/* src/App.scss */

.app-container {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.navigation-menu {
  width: 250px;
  background-color: #e0e0e0;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  flex-shrink: 0; /* Prevent the navigation menu from shrinking */
}

.content {
  flex-grow: 1; /* Allow the content to take up the remaining space */
  padding: 20px;
  background-color: #fff;
  overflow-y: auto; /* Ensure content is scrollable if too tall */
}
