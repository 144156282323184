/* Button styling for the "Next" and "Previous" links */
.button-link {
  display: inline-block; /* Or use flex if you need more control */
  background-color: #007bff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  margin: 1.5em 10px 1.5em 0; /* Adds space between buttons */
}

/* Styling for the link inside the button */
.button-link .link-style {
  color: #ffffff;
  text-decoration: none;
}

/* Hover effect */
.button-link:hover {
  background-color: #0056b3;
}
