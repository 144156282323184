/* src/pages/AppHome.scss */
.app-home {
  text-align: center;
  padding: 40px;
}

.app-home h1 {
  font-size: 2.5em;
}

.app-home p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.app-home ul {
  list-style-type: none;
  padding: 0;
}

.app-home ul li {
  margin-bottom: 15px;
}

.app-home ul li a {
  text-decoration: none;
  font-size: 1.5em;
  color: #007bff;
  transition: color 0.2s ease;
}

.app-home ul li a:hover {
  color: #0056b3;
}
